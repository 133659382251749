import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Breadcrumb, Row, Col } from "react-bootstrap"
import { Section } from "../components/Section"
import PatternImg from "../images/line-pattern.png"
import { MapIcon, PhoneIcon, EmailIcon } from "../components/Icons"
import GetTouchForm from "../components/GetTouchForm"

const ContactUs = ({location}) => {
  let formId = "GET IN TOUCH"
  return (
      <Layout location = { location }>
        <SEO title="Contact Us" />
        <section style={{ background: `url(${PatternImg})` }}>
          <Container className="position-relative">
            <Breadcrumb className="beadcrumbs-outer">
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </section>
        <Section className="section" pt="100px" pb="70px">
          <Container>
            <Row>
              <Col lg={{ span: 8, offset: 2 }}>
                <div className="section-heading text-center">
                  <span className="section-subtitle">GET IN TOUCH</span>
                  <h2 className="section-title">Carports R Us Contact Us</h2>
                  <p>At Carports R Us, nothing makes us happier than having the opportunity to meet and exceed all of your metal building needs.</p>
                </div>
              </Col>
              <Col md={4}>
                <div className="contact-details">
                  <div className="circle-80">
                    <MapIcon fill="#565969" />
                  </div>
                  <span className="title title-underline">Address</span>
                  <span className="description">
                    915, Newsome St. Mount Airy, NC 27030
                  </span>
                </div>
              </Col>
              <Col md={4}>
                <div className="contact-details">
                  <div className="circle-80">
                    <PhoneIcon fill="#565969" />
                  </div>
                  <span className="title title-underline">Phone Number</span>
                  <div className="description">
                    <a href="tel:8773304846">(877) 330-4846</a>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="contact-details">
                  <div className="circle-80">
                    <EmailIcon fill="#565969" />
                  </div>
                  <span className='title  title-underline'>Email Address</span>
                  <a className='description' href="mailto:carportrus@gmail.com" target="_blank" rel="noopener noreferrer" >carportrus@gmail.com</a>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="section" pt="100px" pb="70px" bg="#f7f7f7">
          <Container>
            <div className="section-heading text-center">
              <h2 className="section-title">GET IN TOUCH</h2>
            </div>
            <GetTouchForm location = { location } formId = { formId } />
          </Container>
        </Section>
      </Layout>
  )
}

export default ContactUs
