import React from "react"
import { Button, Form, Row, Col } from 'react-bootstrap'
import Ripples from 'react-ripples'
import axios from 'axios'
import { navigate } from "gatsby"

class GetTouchForm extends React.Component {
	constructor(props) {
		super(props)
		this.state={
			full_name: '',
			email: '',
			mobile_no: '',
			zip_code: '',
			comment: '',
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
	handleChange(event) {
		const {id, value} = event.target
		this.setState({
			[id]: value
		})
    console.log(event, "eventidvalue 11", id, value);
	}
	handleSubmit(event) {
		event.preventDefault()
    const { location } = this.props;
		const data = {
      source: location.origin,
      page: location.href,
      form: event.target.id,
			full_name: this.state.full_name,
			email: this.state.email,
			mobile_no: this.state.mobile_no,
			zip_code: this.state.zip_code,
			comment: this.state.comment,
		}
		axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.FORM_CRM_TOKEN }
    }).then((res) => {
        this.setState({source: '', page: '', form: '', full_name: '', email: '', mobile_no: '', zip_code: '', comment: ''});
        navigate("/thanks");
      }).catch((error) => {
          alert(error)
      });
	}
	render() {
    const {formId} = this.props;
	return(	
		<Form id={formId} onSubmit={this.handleSubmit}>
      <Row>
        <Col md={6}>
          <div className="form-group">
            <input type="text" id="full_name" className="form-control form-control-border" placeholder="Full Name *" onChange={this.handleChange} required />
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input type="email" id="email" className="form-control form-control-border" placeholder="Email Address *" onChange={this.handleChange} required />
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input type="tel" id="mobile_no" className="form-control form-control-border" placeholder="Phone Number *" onChange={this.handleChange}  pattern="[0-9]{10}" required />
          </div>
        </Col>
        <Col md={6}>
          <div className="form-group">
            <input type="text" id="zip_code" className="form-control form-control-border" placeholder="Zip Code *" onChange={this.handleChange} required />
          </div>
        </Col>           
        <Col lg={12}>
          <div className="form-group">
            <textarea id="comment" className='form-control form-control-border w-100 no-resize' placeholder='Type your message here...' onChange={this.handleChange} required />
          </div>
        </Col>
        <Col lg={12} className="form-action text-center">
          <Ripples className='border-radius-27' ><Button variant="secondary" type="submit">Submit</Button></Ripples>
        </Col>
      </Row>
    </Form>
	)
}
}


export default GetTouchForm